import React, { FC } from 'react';

import * as Style from './footer.module.scss';

export const Footer: FC = () => (
  <footer className={Style.footer}>
    <p className={Style.main}>全国パン共通券</p>
    <p lang="en" className={Style.sub}>
      © YuaBread, Inc. All rights reserved.
    </p>
  </footer>
);
