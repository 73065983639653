import React from 'react';
import { Header } from 'components/header/header';
import { Prefacture } from 'components/prefacture/prefacture';
import { Footer } from 'components/footer/footer';
import { Layout } from 'components/layout/layout';
import { Seo } from 'components/seo';

import 'styles/default.scss';

const PrefacturePage = () => (
  <Layout>
    <Seo title="都道府県から探す" />
    <Header title="都道府県から探す" />
    <main>
      <Prefacture />
    </main>
    <Footer />
  </Layout>
);

export default PrefacturePage;
