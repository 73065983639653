import React from 'react';
import { Logo } from 'components/logo/logo';

import * as Style from './header.module.scss';

export interface HeaderProps {
  title: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => (
  <header className={Style.header}>
    <Logo />
    <h1>SHOPS</h1>
    <h2>{title}</h2>
  </header>
);
