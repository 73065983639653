import React from 'react';
import {
  structPrefactures,
  RegionalDivision,
  regionalDivisions,
  Prefacture as PrefactureType,
} from 'const/prefacture';
import { graphql, Link, useStaticQuery } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { PrefactureCell } from 'components/prefactureCell/prefactureCell';
import * as Style from './prefacture.module.scss';

export const Prefacture: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.PrefactureQuery>(graphql`
    query Prefacture {
      count: allContentfulSellerLocation {
        totalCount
      }
      allContentfulSellerLocation(filter: { node_locale: { eq: "ja-JP" } }) {
        edges {
          node {
            prefecture
          }
        }
      }
    }
  `);
  const counts = data.allContentfulSellerLocation.edges
    .map((n) => n.node.prefecture as PrefactureType)
    .reduce((prev, key) => {
      const newValue = { ...prev };
      if (key in newValue) {
        newValue[key] += 1;
      } else {
        newValue[key] = 1;
      }

      return newValue;
    }, {} as Record<PrefactureType, number>);

  const rDivisions = (
    <ul>
      {regionalDivisions.map((key, index) => {
        return (
          <AnchorLink key={key} href={`#p${index}`}>
            <li>
              <div className={Style.regListItem}>{key}</div>
              <div className={Style.anker} />
            </li>
          </AnchorLink>
        );
      })}
    </ul>
  );

  const prefactures = (
    Object.keys(structPrefactures) as RegionalDivision[]
  ).map((key, index) => (
    <div key={key} className={Style.prefacture}>
      <p id={`p${index}`}>{key}</p>
      <ul>
        {structPrefactures[key].map((pk) => (
          <PrefactureCell key={pk} count={counts[pk]} prefacture={pk} />
        ))}
      </ul>
    </div>
  ));

  return (
    <div className={Style.root}>
      <div className={Style.regionalDivisions}>{rDivisions}</div>
      <div className={Style.prefactures}>{prefactures}</div>
      <div className={Style.backToTop}>
        <Link to="/">＜ 店舗検索トップへ戻る</Link>
      </div>
    </div>
  );
};
