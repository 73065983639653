import React from 'react';
import { Link } from 'gatsby';

import * as Style from './prefactureCell.module.scss';

export type PrefactureCellProps = {
  count: number | undefined;
  prefacture: string;
};

export const PrefactureCell: React.FC<PrefactureCellProps> = ({
  count,
  prefacture,
}) => {
  if (count === undefined) {
    return <li className={Style.disable}>{prefacture}（0件）</li>;
  }

  return (
    <Link to={`/map?prefacture=${prefacture}`}>
      <li className={Style.enable}>
        {prefacture}（{`${count}`}件）
        <div className={Style.chevronRight} />
      </li>
    </Link>
  );
};
